<template>
  <div
    id="neighborhood-detail"
    :class="'community-' + route?.params?.community"
  >
    <MoleculesLoadingOverlay v-if="loading" />
    <div v-else>
      <OrganismsUpcomingEventBanner
        v-if="upcomingEvent?.uri"
        :organism="upcomingEvent"
      />
      <OrganismsBWBoxOverlay
        :organism="brandWindow"
        :class="hideTabbedContent ? 'two-col-buttons' : 'three-col-buttons'"
      />
      <OrganismsFlexibleContentColumns
        id="icon-columns"
        :organism="neighborhoodFeatures"
      />
      <OrganismsSimpleCentered :organism="simpleCentered" />
      <div>
        <OrganismsSplitContent
          v-for="item in alternatingContent"
          :organism="item"
        />
      </div>
      <OrganismsTabbedContent
        v-if="!hideTabbedContent"
        id="see-homes"
        :key="tabbedContentKey"
        :organism="tabbedContent"
      >
        <template v-for="item in tabbedContent?.item" #[item.id]>
          <h2
            v-if="item.id === 'floorplans'"
            class="text-2xl leading-7 sm:leading-10 sm:text-[32px] font-bold font-figtree text-center text-gray-900 pt-12"
          >
            {{
              getNeighborhoodNavSubtitle(
                neighborhood.types,
                getRoundedPrice(neighborhood.basePrice),
                neighborhood.neighborhood?.status,
              )
            }}
          </h2>

          <h2
            v-if="showNoResultsText(productsByCollection)"
            class="pt-12 pb-16 text-xl text-center text-gray-900 no-results-text font-figtree"
          >
            There are currently no {{ item.name }} at this neighborhood. Please
            choose a different tab above.
          </h2>
          <div
            v-for="collection in neighborhoodCollections"
            class="pb-12 last:pb-28 mx-auto max-w-[1220px]"
          >
            <AtomsCollectionButton
              v-if="
                !showNoResultsText(productsByCollection) &&
                productsByCollection?.[collection]?.length
              "
              :collection="collection"
              :class="[
                'flex items-center justify-center mx-auto !my-0 !py-12 text-center [&>span]:font-bold [&>span]:text-[32px] [&>img]:w-11 w-fit community-collection-btn',
              ]"
              disableShadow
              coloredText
            />
            <OrganismsProductGrid
              v-if="
                collection?.includes('duplex') &&
                productsByCollection?.duplex?.length > 0 &&
                Array.isArray(productsByCollection?.['duplex'])
              "
              :key="collection.id"
              :organism="productsByCollection.duplex ?? []"
              :type="productsByCollection?.type"
              :neighborhood="neighborhood"
            />
            <OrganismsProductGrid
              v-if="
                collection?.includes('pioneer') &&
                productsByCollection?.pioneer?.length > 0 &&
                Array.isArray(productsByCollection?.['pioneer'])
              "
              :key="collection.id"
              :organism="productsByCollection.pioneer ?? []"
              :type="productsByCollection?.type"
              :neighborhood="neighborhood"
            />
            <OrganismsProductGrid
              v-if="
                collection === 'heritage' &&
                productsByCollection?.['heritage']?.length > 0 &&
                Array.isArray(productsByCollection?.['heritage'])
              "
              :key="collection.id"
              :organism="productsByCollection?.['heritage'] ?? []"
              :type="productsByCollection?.type"
              :neighborhood="neighborhood"
            />
            <OrganismsProductGrid
              v-if="
                collection === 'heritage-i' &&
                productsByCollection['heritage-i']?.length > 0 &&
                Array.isArray(productsByCollection?.['heritage-i'])
              "
              :key="collection.id"
              :organism="productsByCollection['heritage-i'] ?? []"
              :type="productsByCollection?.type"
              :neighborhood="neighborhood"
            />
            <OrganismsProductGrid
              v-if="
                collection === 'legacy' &&
                productsByCollection['legacy']?.length > 0 &&
                Array.isArray(productsByCollection?.['legacy'])
              "
              :key="collection.id"
              :organism="productsByCollection['legacy'] ?? []"
              :type="productsByCollection?.type"
              :neighborhood="neighborhood"
            />
            <OrganismsProductGrid
              v-if="
                collection === 'legacy-ii' &&
                productsByCollection['legacy-ii']?.length > 0 &&
                Array.isArray(productsByCollection?.['legacy-ii'])
              "
              :key="collection.id"
              :organism="productsByCollection['legacy-ii'] ?? []"
              :type="productsByCollection?.type"
              :neighborhood="neighborhood"
            />
            <OrganismsProductGrid
              v-if="
                collection?.includes('townhome') &&
                productsByCollection.townhome.length > 0 &&
                Array.isArray(productsByCollection?.['townhome'])
              "
              :key="collection.id"
              :organism="productsByCollection.townhome ?? []"
              :type="productsByCollection?.type"
              :neighborhood="neighborhood"
            />
          </div>
        </template>
      </OrganismsTabbedContent>
      <OrganismsSiteMapCTA
        id="site-map"
        v-if="siteMapCTA?.link1"
        :organism="siteMapCTA"
      />
      <OrganismsSimpleIframe v-if="hasIframe" :organism="simpleIframe" />
      <OrganismsFormCTA :organism="formCTA" />
      <OrganismsAccordionFaq
        v-if="accordionFaq?.items.length"
        :organism="{ accordion: accordionFaq?.items }"
        :headerTitle="accordionFaq.headerTitle"
        :headerDescription="accordionFaq.headerDescription"
      />
      <OrganismsMapWithInfo id="map-with-info" :organism="mapWithInfo" />
      <OrganismsPopularNeighborhoods
        v-if="hasVisibleNeighborhoods"
        :organism="popularNeighborhoods"
      />
      <OrganismsTextCTA
        id="cta-be-the-first"
        class="pt-[60px] pb-12 mx-auto max-w-screen-xl my-20"
        :organism="beTheFirst"
      />
      <OrganismsModalForm>
        <header class="mb-4">
          <h2 class="mb-4 text-4xl font-semibold text-black font-figtree">
            Be the First
          </h2>
          <p v-if="!isFormSubmitted" class="text-lg text-gray-500">
            Please provide us your information and we will get back to you.
          </p>
        </header>
        <FormsHubSpotForm
          :form="{ id: '6661d0e5-7ddc-4182-a143-f1f988baf283' }"
          @submit="isFormSubmitted = true"
        />
      </OrganismsModalForm>
    </div>
    <OrganismsModalLogin />
  </div>
</template>

<style>
#neighborhood-detail {
  &.community-lanterns-at-rock-creek {
  }

  .brand-window-box-overlay .buttons {
    @apply md:!grid md:grid-cols-3 mx-auto;

    a {
      @apply !text-[15px] leading-4 uppercase font-medium px-6 md:whitespace-nowrap;
    }
  }
  .brand-window-box-overlay.two-col-buttons .buttons {
    @apply md:grid-cols-2;

    .button-1-wrapper {
      @apply hidden;
    }
  }

  #icon-columns {
    @apply text-center text-white pt-10 pb-14 px-8;

    img {
      @apply w-14 inline-block pb-4;
    }

    .feature-title {
      @apply font-figtree text-lg uppercase font-bold;
    }

    .description {
      @apply font-figtree text-lg;
    }
  }

  .image img {
    @apply w-[1147px] max-w-full;
  }

  .tabbed-content {
    header {
      background-image: linear-gradient(101deg, #1b3f68 0%, #265a94 97%);
      .header-content {
        @apply max-w-[556px];
      }
    }
    .tabs-component-tabs {
      @apply w-[556px] mx-auto;
    }
  }

  .community-collection-btn span {
    @apply font-cg text-[39px];
  }

  @media screen and (max-width: 767px) {
    #map-wrap {
      @apply hidden;
    }
  }
}
</style>

<script setup>
//import { useQuery } from "@vue/apollo-composable";
import { useAsyncQuery } from "#imports";
import { GET_NEIGHBORHOOD } from "~/graphql/GetNeighborhood";
import states from "us-state-converter";
import { storeToRefs } from "pinia";
const route = useRoute();
const breadcrumbs = useBreadcrumbs();
const { breadcrumbsList } = storeToRefs(breadcrumbs);
const { data, pending, error } = await useAsyncQuery(GET_NEIGHBORHOOD, {
  uri: route.path,
});
const neighborhoodDetails = useNeighborhoodDetails();
const { mapInfo } = storeToRefs(neighborhoodDetails);
const modalForm = useModalForm();
const isFormSubmitted = ref(false);
const modalLogin = useModalLogin();
const { modalIsOpen } = storeToRefs(modalLogin);
const tabbedContentKey = ref(0);

// // Turn off ssr for community pages
// definePageMeta({
//   ssr: false,
// });

const loading = pending;

const neighborhood = computed(() => {
  return data?.value?.neighborhood ?? [];
});

const neighborhoods = computed(() => {
  return data?.value?.neighborhoods?.nodes ?? [];
});

const plans = computed(() => {
  return data?.value?.plans ?? [];
});

const models = computed(() => {
  return data?.value?.models ?? [];
});

const homes = computed(() => {
  return data?.value?.homes ?? [];
});

const locations = computed(() => {
  return data?.value?.locations ?? [];
});

const computedBreadcrumbs = computed(() => {
  return [
    { title: "Our Communities", link: "/our-homes/communities/" },
    {
      title: neighborhood.value.title
        ? neighborhood.value.title +
          ", " +
          neighborhood.value.neighborhood?.city
        : "",
    },
  ];
});

watchEffect(() => {
  breadcrumbsList.value = computedBreadcrumbs.value;
});

const upcomingEvent = computed(() => {
  return {
    title: neighborhood.value.neighborhood?.upcomingEvent?.title,
    uri: neighborhood.value.neighborhood?.upcomingEvent?.uri,
  };
});

const neighborhoodCollections = computed(() => {
  return getCollectionsByNeighborhood(
    neighborhood.value.slug,
    plans.value.nodes,
    homes.value.nodes,
    models.value.nodes,
    [neighborhood.value.collection?.collection],
  );
});

const neighborhoodFeatures = computed(() => {
  //console.log('neighborhoodFeatures:', neighborhood.value.neighborhood?.neighborhoodFeatures);
  return {
    type: "flexible-content-columns",
    backgroundColor: "#3d85c6",
    containerWidth: "full-width",
    item: neighborhood.value.neighborhood?.neighborhoodFeatures ?? [],
  };
});

const simpleCentered = computed(() => {
  //console.log(productsByCollection.value);
  let title1 = "New homes in";
  if (
    productsByCollection.value &&
    productsByCollection.value.duplex &&
    productsByCollection.value.duplex.length > 0
  ) {
    title1 = "New Duplexes in";
  }
  return {
    backgroundColor: "#EEEEEE",
    backgroundImage: {
      sourceUrl:
        "https://flintrockbuilders.kinsta.cloud/wp-content/uploads/2024/02/nolanville_skinny-scaled.jpg",
    },
    backgroundImageOpacity: 0.1,
    containerWidth: "full-width",
    title1: title1,
    title2:
      neighborhood.value.neighborhood?.city +
      ", " +
      states.fullName(neighborhood.value.neighborhood?.state),
    text1: neighborhood.value.neighborhood?.description,
  };
});

const alternatingContent = computed(() => {
  return Array.isArray(neighborhood.value.neighborhood?.alternatingContent)
    ? neighborhood.value.neighborhood?.alternatingContent.map((item, index) => {
        return {
          backgroundColor: index % 2 ? "#f0f0f0" : "#ffffff",
          splitContentLayout: index % 2 ? "image-text" : "text-image",
          title1: item.title,
          description: item.description,
          button1: item.button,
          button1Text: item.buttonText,
          link1: item.link,
          videoEmbed: item.videoEmbed,
          image: item.image,
          alt: item.image?.alt,
          fullBleed: true,
        };
      })
    : [];
});

const accordionFaq = computed(() => {
  const neighborhoodData = neighborhood.value.neighborhood || {};
  const accordionItems = Array.isArray(neighborhoodData.accordion)
    ? neighborhoodData.accordion.map((item) => ({
        title1: item.title1,
        title2: item.title2,
        description: item.description,
      }))
    : [];

  return {
    items: accordionItems,
    headerTitle: neighborhoodData.accordionTitle || "",
    headerDescription: neighborhoodData.accordionDescription || "",
  };
});

const beTheFirst = {
  title1: "Be the First",
  description:
    "<p>Be the first to know about our Central Texas communities and homes, including early access to pricing, new home plans and VIP events.</p>",
  button1: true,
  button1Text: "Get Updates",
  link1: "#modal-form",
};

const hideTabbedContent = computed(() => {
  const objs = [
    floorplansByCollection?.value,
    modelHomesByCollection?.value,
    quickMoveInByCollection?.value,
  ];
  return objs.every((obj) => {
    return (
      Object.values(obj).filter((arr) => Array.isArray(arr) && arr.length > 0)
        .length === 0
    );
  });
});

const brandWindow = computed(() => {
  //console.log('Neighborhood Data:', neighborhood.value);
  return {
    type: "brand-window",
    collectionIcons: neighborhoodCollections.value,
    title1: neighborhood?.value?.title ?? "",
    title2: neighborhood.value.neighborhood?.city
      ? neighborhood.value.neighborhood?.city +
        ", " +
        neighborhood.value.neighborhood?.state
      : "",
    title3:
      getNeighborhoodNavSubtitle(
        neighborhood.value.types,
        getRoundedPrice(neighborhood.value.basePrice),
        neighborhood.value.neighborhood?.status,
      ) ?? "",
    text1:
      getNeighborhoodStatusLabel(neighborhood.value.neighborhood?.status) ?? "",
    description: null,
    button1Color: "button-blue",
    button2Color: "button-white",
    button3Color: "button-yellow",
    button1: true,
    button1Text: "See Homes",
    link1: "#see-homes",
    button2: true,
    button2Text: "Get Directions",
    button2Target: "_blank",
    link2: neighborhood.value.neighborhood?.latitude
      ? "https://www.google.com/maps/place/" +
        neighborhood.value.neighborhood?.latitude +
        "," +
        neighborhood.value.neighborhood?.longitude
      : "",
    button3: true,
    button3Text: "View Location",
    link3: "#map-with-info",
    containerWidth: "full-width",
    brandWindowLayout: "box-overlay",
    backgroundImage: neighborhood.value.neighborhood?.thumbnail,
    backgroundVideoFile: neighborhood.value.neighborhood?.backgroundVideoFile,
  };
});

const addTriggers = () => {
  const modalFormTriggers = document.querySelectorAll("a[href$='#modal-form']");

  modalFormTriggers.forEach((node) => {
    // Clone & replace node to remove existing event listeners
    const clonedNode = node.cloneNode(true);
    node.parentNode.replaceChild(clonedNode, node);

    clonedNode.addEventListener("click", (event) => {
      // Add event listener
      event.preventDefault();
      modalForm.openModal();
    });
  });
};

onUpdated(() => {
  addTriggers();
});

const popularNeighborhoods = computed(() => {
  const items = getPopularNeighborhoods(
    neighborhood.value.neighborhood?.popularNeighborhoods,
    neighborhoods.value,
    neighborhood.value.databaseId,
    neighborhood.value.hideCommunity,
  );
  //console.log('getPopularNeighborhoods return value:', items);
  return {
    backgroundColor: "#f0f0f0",
    items: items,
    plans: plans.value.nodes,
  };
});

const hasVisibleNeighborhoods = computed(() => {
  if (!Array.isArray(popularNeighborhoods.value?.items)) {
    return false;
  }
  const visible = popularNeighborhoods.value?.items.some(
    (item) => !item.neighborhood.hideCommunity,
  );
  //console.log('Visible neighborhoods:', visible);
  return visible;
});

const tabbedContent = computed(() => {
  const items = [];

  const showModelsTab = models.value?.nodes?.filter(
    (model) =>
      model.modelDetails?.neighborhood?.slug === neighborhood.value?.slug,
  ).length;

  const showFloorplansTab = plans.value?.nodes?.filter((plan) => {
    //console.log('Checking plan:', plan);
    const matches = plan.floorplanDetails?.neighborhood?.some(
      (currentNeighborhood) => {
        //console.log('Checking neighborhood:', currentNeighborhood);
        const match = currentNeighborhood.slug === neighborhood.value?.slug;
        //console.log('Match:', match);
        return match;
      },
    );
    //console.log('Matches:', matches);
    return matches;
  }).length;

  const showQMIsTab = homes.value?.nodes?.filter(
    (home) =>
      home.qmiHomeDetails?.neighborhood?.slug === neighborhood.value?.slug,
  ).length;

  if (showModelsTab) {
    items.push({ name: "Model Homes", id: "model-homes" });
  }
  if (showFloorplansTab) {
    items.push({ name: "Floorplans", id: "floorplans" });
  }
  if (showQMIsTab) {
    items.push({ name: "Quick Move-In", id: "quick-move-in" });
  }

  return {
    containerWidth: "default",
    collectionIcons: neighborhoodCollections.value,
    title1: "New homes at " + neighborhood.value.title,
    title2:
      neighborhood.value.neighborhood?.city +
      ", " +
      neighborhood.value.neighborhood?.state,
    item: items,
    defaultTabHash: showFloorplansTab ? "floorplans" : "quick-move-in",
  };
});

const modelHomesByCollection = computed(() => {
  const neighborhoodSlug = neighborhood.value.slug;
  if (!neighborhoodSlug) return {};

  return {
    type: "model-homes",
    duplex:
      models?.value?.nodes?.filter((model) => {
        const collection =
          model?.collection?.collection ??
          model?.modelDetails?.floorplan?.floorplanDetails?.collection ??
          "";
        const modelNeighborhoodSlug = model?.modelDetails?.neighborhood?.slug;
        return (
          collection.includes("duplex") &&
          modelNeighborhoodSlug === neighborhoodSlug
        );
      }) ?? [],
    pioneer:
      models?.value?.nodes?.filter((model) => {
        const collection =
          model?.collection?.collection ??
          model?.modelDetails?.floorplan?.floorplanDetails?.collection ??
          "";
        const modelNeighborhoodSlug = model?.modelDetails?.neighborhood?.slug;
        return (
          collection.includes("pioneer") &&
          modelNeighborhoodSlug === neighborhoodSlug
        );
      }) ?? [],
    heritage:
      models?.value?.nodes?.filter((model) => {
        const collection =
          model?.collection?.collection ??
          model?.modelDetails?.floorplan?.floorplanDetails?.collection ??
          "";
        const modelNeighborhoodSlug = model?.modelDetails?.neighborhood?.slug;
        return (
          collection === "heritage" &&
          modelNeighborhoodSlug === neighborhoodSlug
        );
      }) ?? [],
    "heritage-i":
      models?.value?.nodes?.filter((model) => {
        const collection =
          model?.collection?.collection ??
          model?.modelDetails?.floorplan?.floorplanDetails?.collection ??
          "";
        const modelNeighborhoodSlug = model?.modelDetails?.neighborhood?.slug;
        return (
          collection === "heritage-i" &&
          modelNeighborhoodSlug === neighborhoodSlug
        );
      }) ?? [],
    legacy:
      models?.value?.nodes?.filter((model) => {
        const collection =
          model?.collection?.collection ??
          model?.modelDetails?.floorplan?.floorplanDetails?.collection ??
          "";
        const modelNeighborhoodSlug = model?.modelDetails?.neighborhood?.slug;
        return (
          collection === "legacy" && modelNeighborhoodSlug === neighborhoodSlug
        );
      }) ?? [],
    "legacy-ii":
      models?.value?.nodes?.filter((model) => {
        const collection =
          model?.collection?.collection ??
          model?.modelDetails?.floorplan?.floorplanDetails?.collection ??
          "";
        const modelNeighborhoodSlug = model?.modelDetails?.neighborhood?.slug;
        return (
          collection === "legacy-ii" &&
          modelNeighborhoodSlug === neighborhoodSlug
        );
      }) ?? [],
    townhome:
      models?.value?.nodes?.filter((model) => {
        const collection =
          model?.collection?.collection ??
          model?.modelDetails?.floorplan?.floorplanDetails?.collection ??
          "";
        const modelNeighborhoodSlug = model?.modelDetails?.neighborhood?.slug;
        return (
          collection.includes("townhome") &&
          modelNeighborhoodSlug === neighborhoodSlug
        );
      }) ?? [],
  };
});

const floorplansByCollection = computed(() => {
  return {
    type: "floorplans",
    duplex: plans?.value?.nodes?.filter((plan) => {
      return (
        plan.floorplanDetails?.collection?.includes("duplex") &&
        plan.floorplanDetails?.neighborhood?.some(
          (currentNeighborhood) =>
            currentNeighborhood.slug === neighborhood.value.slug,
        )
      );
    }),
    pioneer: plans?.value?.nodes?.filter((plan) => {
      return (
        plan.floorplanDetails?.collection?.includes("pioneer") &&
        plan.floorplanDetails?.neighborhood?.some(
          (currentNeighborhood) =>
            currentNeighborhood.slug === neighborhood.value.slug,
        )
      );
    }),
    heritage: plans?.value?.nodes?.filter((plan) => {
      return (
        plan.floorplanDetails?.collection === "heritage" &&
        plan.floorplanDetails?.neighborhood?.some(
          (currentNeighborhood) =>
            currentNeighborhood.slug === neighborhood.value.slug,
        )
      );
    }),
    "heritage-i": plans?.value?.nodes?.filter((plan) => {
      return (
        plan.floorplanDetails?.collection === "heritage-i" &&
        plan.floorplanDetails?.neighborhood?.some(
          (currentNeighborhood) =>
            currentNeighborhood.slug === neighborhood.value.slug,
        )
      );
    }),
    legacy: plans?.value?.nodes?.filter((plan) => {
      return (
        plan.floorplanDetails?.collection === "legacy" &&
        plan.floorplanDetails?.neighborhood?.some(
          (currentNeighborhood) =>
            currentNeighborhood.slug === neighborhood.value.slug,
        )
      );
    }),
    "legacy-ii": plans?.value?.nodes?.filter((plan) => {
      return (
        plan.floorplanDetails?.collection === "legacy-ii" &&
        plan.floorplanDetails?.neighborhood?.some(
          (currentNeighborhood) =>
            currentNeighborhood.slug === neighborhood.value.slug,
        )
      );
    }),
    townhome: plans?.value?.nodes?.filter((plan) => {
      return (
        plan.floorplanDetails?.collection?.includes("townhome") &&
        plan.floorplanDetails?.neighborhood?.some(
          (currentNeighborhood) =>
            currentNeighborhood.slug === neighborhood.value.slug,
        )
      );
    }),
  };
});

const quickMoveInByCollection = computed(() => {
  const neighborhoodSlug = neighborhood.value.slug;
  return {
    type: "quick-move-in",
    duplex: homes?.value?.nodes?.filter((home) => {
      const collection =
        home.qmiHomeDetails?.floorplan?.floorplanDetails?.collection ??
        home.collection?.collection ??
        "";
      const homeNeighborhoodSlug = home.qmiHomeDetails?.neighborhood?.slug;
      return (
        collection.includes("duplex") &&
        homeNeighborhoodSlug === neighborhoodSlug
      );
    }),
    pioneer: homes?.value?.nodes?.filter((home) => {
      const collection =
        home.qmiHomeDetails?.floorplan?.floorplanDetails?.collection ??
        home.collection?.collection ??
        "";
      const homeNeighborhoodSlug = home.qmiHomeDetails?.neighborhood?.slug;
      return (
        collection.includes("pioneer") &&
        homeNeighborhoodSlug === neighborhoodSlug
      );
    }),
    heritage: homes?.value?.nodes?.filter((home) => {
      const collection =
        home.qmiHomeDetails?.floorplan?.floorplanDetails?.collection ??
        home.collection?.collection ??
        "";
      const homeNeighborhoodSlug = home.qmiHomeDetails?.neighborhood?.slug;
      return (
        collection === "heritage" && // Use strict equality for heritage
        homeNeighborhoodSlug === neighborhoodSlug
      );
    }),
    "heritage-i": homes?.value?.nodes?.filter((home) => {
      const collection =
        home.qmiHomeDetails?.floorplan?.floorplanDetails?.collection ??
        home.collection?.collection ??
        "";
      const homeNeighborhoodSlug = home.qmiHomeDetails?.neighborhood?.slug;
      return (
        collection === "heritage-i" && // Use strict equality for heritage-i
        homeNeighborhoodSlug === neighborhoodSlug
      );
    }),
    legacy: homes?.value?.nodes?.filter((home) => {
      const collection =
        home.qmiHomeDetails?.floorplan?.floorplanDetails?.collection ??
        home.collection?.collection ??
        "";
      const homeNeighborhoodSlug = home.qmiHomeDetails?.neighborhood?.slug;
      return (
        collection === "legacy" && // Use strict equality for legacy
        homeNeighborhoodSlug === neighborhoodSlug
      );
    }),
    "legacy-ii": homes?.value?.nodes?.filter((home) => {
      const collection =
        home.qmiHomeDetails?.floorplan?.floorplanDetails?.collection ??
        home.collection?.collection ??
        "";
      const homeNeighborhoodSlug = home.qmiHomeDetails?.neighborhood?.slug;
      return (
        collection === "legacy-ii" && // Use strict equality for legacy-ii
        homeNeighborhoodSlug === neighborhoodSlug
      );
    }),
    townhome: homes?.value?.nodes?.filter((home) => {
      const collection =
        home.qmiHomeDetails?.floorplan?.floorplanDetails?.collection ??
        home.collection?.collection ??
        "";
      const homeNeighborhoodSlug = home.qmiHomeDetails?.neighborhood?.slug;
      return (
        collection.includes("townhome") &&
        homeNeighborhoodSlug === neighborhoodSlug
      );
    }),
  };
});

const productsByCollection = computed(() => {
  // Default empty state
  const defaultCollections = {
    type: "",
    duplex: [],
    pioneer: [],
    heritage: [],
    "heritage-i": [],
    legacy: [],
    "legacy-ii": [],
    townhome: [],
  };

  // If we don't have a current tab, return defaults
  if (!neighborhoodDetails?.currentTab) {
    return defaultCollections;
  }

  let result;
  switch (neighborhoodDetails.currentTab) {
    case "floorplans":
      result = floorplansByCollection.value;
      break;
    case "model-homes":
      result = modelHomesByCollection.value;
      break;
    case "quick-move-in":
      result = quickMoveInByCollection.value;
      break;
    default:
      result = defaultCollections;
  }

  // Merge with defaults to ensure all properties exist
  return { ...defaultCollections, ...result };
});

const showCollectionHeader = (iconId, products) => {
  switch (iconId) {
    case "duplex":
      return products.duplex.length;
    case "pioneer":
      return products.pioneer.length;
    case "heritage":
      return products.heritage.length;
    case "heritage-i":
      return products["heritage-i"]?.length;
    case "legacy":
      return products.legacy.length;
    case "legacy-ii":
      return products["legacy-ii"]?.length;
    case "townhome":
      return products.townhome.length;
  }
};

const showNoResultsText = (products) => {
  return (
    products?.duplex?.length === 0 &&
    products?.pioneer?.length === 0 &&
    products?.heritage?.length === 0 &&
    products["heritage-i"]?.length === 0 &&
    products?.legacy?.length === 0 &&
    products["legacy-ii"]?.length === 0 &&
    products?.townhome?.length === 0
  );
};

const siteMapCTA = computed(() => {
  return {
    containerWidth: "full-width",
    title1: "Explore the homesites at " + neighborhood.value.title,
    backgroundColor: "#2b5d8b",
    backgroundImage: {
      sourceUrl:
        "https://flintrockbuilders.kinsta.cloud/wp-content/uploads/2024/08/divider-map-bg-flintrock.jpg",
    },
    image: {
      sourceUrl:
        "https://flintrockbuilders.kinsta.cloud/wp-content/uploads/2024/08/siteMap-thumb-flintrock-md.jpg",
    },
    backgroundImageOpacity: "0.4",
    buttonColors: ["button-yellow"],
    button1: true,
    button1Text: "See homesites",
    button1Target: "_blank",
    link1: neighborhood?.value?.neighborhood?.siteMapPdfEPub ?? false,
  };
});

const simpleIframe = computed(() => {
  return {
    title1: "Explore this Neighborhood",
    title2: neighborhood?.value?.title,
    iframe: neighborhood?.value?.neighborhood?.iframeSitemap,
  };
});

const hasIframe = computed(
  () => simpleIframe.value && simpleIframe.value.iframe,
);

const formCTA = computed(() => {
  return {
    containerWidth: "full-width",
    title1: "Curious about this community?",
    description: `<p>Whether you're just getting started or ready to schedule a tour, our online team is here to help.</p><p>Call <a href="tel:254-217-8717">254-217-8717</a> or send us a message.</p>`,
    image: {
      sourceUrl: "/img/osc-emma.png",
    },
    hubspotFormId: "6e48ba95-e99d-46d6-aab2-95e35bdb623b",
    hiddenFormFields: [
      {
        inputSelector: 'input[name="community_of_interest"]',
        inputValue: neighborhood.value.neighborhood?.hubspotValue ?? "",
      },
    ],
  };
});

watch(neighborhood, () => {
  const form =
    document.querySelector("#hsForm_6e48ba95-e99d-46d6-aab2-95e35bdb623b") ??
    false;

  if (!form) return;

  const hiddenFields = [
    {
      inputSelector: 'input[name="community_of_interest"]',
      inputValue: neighborhood.value.neighborhood?.hubspotValue ?? "",
    },
  ];

  if (Array.isArray(hiddenFields)) {
    hiddenFields.forEach((field) => {
      const input = form.querySelector(field.inputSelector);
      input.value = field.inputValue;
    });
  }
});

const mapWithInfo = computed(() => {
  const hours = computed(() => {
    const salesOfficeLocation = Array.isArray(locations?.value?.nodes)
      ? locations?.value?.nodes.find(
          (location) =>
            location?.locationDetails?.neighborhood[0]?.slug ===
            neighborhood.value.slug,
        )
      : {};

    return (
      salesOfficeLocation?.locationDetails?.hours ??
      neighborhood.value.neighborhood?.hours
    );
  });

  return {
    markerTitle: neighborhood.value.title,
    title1: "Stop by for a visit",
    address1: neighborhood.value.neighborhood?.address1,
    address2: neighborhood.value.neighborhood?.address2,
    city: neighborhood.value.neighborhood?.city,
    state: neighborhood.value.neighborhood?.state,
    zip: neighborhood.value.neighborhood?.zip,
    latitude: neighborhood.value.neighborhood?.latitude,
    longitude: neighborhood.value.neighborhood?.longitude,
    hours: hours.value,
    phone: neighborhood.value.neighborhood?.phone,
    salesTeamMembers: neighborhood.value.neighborhood?.saleTeamMembers,
    legal: neighborhood.value.neighborhood?.legal,
  };
});

const scrollToTabs = (currentHash) => {
  const tabs = ["#model-homes", "#floorplans", "#quick-move-in"];
  if (tabs.includes(currentHash)) {
    const target = document.querySelector("#see-homes");
    if (target) {
      target.scrollIntoView(true);
      return true;
    }
  }
  return false;
};

const mapUrlHash = (currentHash) => {
  const hashMappings = {
    "#tab-floorplans": "#floorplans",
    "#tab-qmi-homes": "#quick-move-in",
    "#tab-model-homes": "#model-homes",
  };
  if (hashMappings.hasOwnProperty(currentHash)) {
    const newHash = hashMappings[currentHash];
    window.location.hash = newHash;
    scrollToTabs(newHash);
    return true;
  }
  return false;
};
/*
const checkAndRedirect = (newData) => {
  try {
    if (!newData || newData.neighborhood === null) {
      window.location.href = '/our-homes/communities'; // Replace with your desired route
    }
  } catch (err) {
    console.error('Error in checkAndRedirect:', err);
  }
};

// Perform the check immediately after data is fetched
checkAndRedirect(data.value);

// Watch for any changes in the data
watch(data, (newData) => {
  checkAndRedirect(newData);
});
*/
onMounted(() => {
  window.addEventListener(
    "hashchange",
    () => {
      const urlHash = window.location.hash;
      if (urlHash === "#modal-form") {
        modalForm.openModal();
      }
      mapUrlHash(urlHash);
    },
    false,
  );

  addTriggers();
});

watch(loading, async (newLoading, oldLoading) => {
  const urlHash = window.location.hash;

  if (oldLoading && !newLoading) {
    await nextTick();
    const handleTabsHash = scrollToTabs(urlHash) ? true : mapUrlHash(urlHash);

    if (urlHash && !handleTabsHash) {
      document.querySelector(urlHash).scrollIntoView(true);
    }
  }
});

const refreshTabContent = async () => {
  await nextTick();
  tabbedContentKey.value++;
};

watch(modalIsOpen, (newValue, oldValue) => {
  refreshTabContent();
});
</script>
